import * as React from 'react'
// material
import { Container } from '@mui/material';
// components
import HomePage from '../components/heroes/HomePage';
import TitlePageBlock from '../components/heroes/TitlePageBlock';
import TextPageBlock from '../components/heroes/TextPageBlock';

// ----------------------------------------------------------------------

export default function Candidates() {
  return (
    <HomePage title="Candidates">
      <TitlePageBlock title="Candidates" subTitle="TODO: Write text about candidates" imgUrl="https://uxwing.com/wp-content/themes/uxwing/download/42-business-professional-services/company-enterprise.png"/>
      <Container maxWidth="lg">
        <TextPageBlock title="Info1" subTitle="Description">test content ...</TextPageBlock>
      </Container>
    </HomePage>
  );
}